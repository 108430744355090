const initialState = 0;
const changeThNumber = (state = initialState, action) => {
  console.log("action.type", action.type);
  switch (action.type) {
    case "INCRIMENT":
      return state + 1;
    case "DICRIMENT":
      return state - 1;
    default:
      return state;
  }
};

export default changeThNumber;
