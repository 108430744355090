const initialState = null;
  
const getEventId  = (state = initialState, action) => {
    console.log("action.type", action);
    switch (action.type) {
        case 'EVENTID':
        return state=action.value
        default:
          return state
      }
  };

  export default getEventId;