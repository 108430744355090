import React from 'react'
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
const AppContent = React.lazy(() => import('./components/AppContent'))
// Pages
/* <Route path="*" name="Home" element={<DefaultLayout />} />
 <Route path="/eventadd" name= "Event" element={<EventAdd/>} /> 
/* const EventAdd = React.lazy(() => import('./views/pages/EventAdd')) */

const App =()=> {
  return(
      <HashRouter>
        <AppContent />
      </HashRouter>
      
  )
}

export default App
